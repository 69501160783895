<!--
File: AdminPanel.vue
Description: shows admin panel with links to open screens for referenca data (Units, Treatments, Rates, etc).
-->
<template>
  <div class="md-layout">
    <md-card v-for="(card, index) in cards" :key="index" class="md-layout-item">
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>{{ card.icon }}</md-icon>
        </div>
        <h4 class="title">{{ $t(card.title) }}</h4>
      </md-card-header>
      <md-card-content>
        <div v-for="(link, linkIndex) in card.links" :key="linkIndex" class="report-link">
          <a href="#" @click.stop.prevent="showScreen(link.url)">
            {{ $t(link.name) }}
          </a>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
  export default {
    name: 'admin-panel',
    data() {
      return {
        cards: [
          {
            icon: 'group',
            title: 'route.user_management',
            links: [
              { url: '/users', name: 'route.users' },
              { url: '/groups', name: 'route.groups' }
            ]
          },
          {
            icon: 'admin_panel_settings',
            title: 'route.treatments',
            links: [
              { url: '/treatments/types', name: 'route.treatment_types' },
              { url: '/treatments/rates', name: 'route.treatment_rates' },
              { url: '/treatments/treatment_matrix', name: 'route.treatment_matrix' },
            //Removed by the issue #76 as indicated by Meyyappan
            //{ url: '/settings/condition_index_criteria', name: 'route.condition_index_criteria' }
            ]
          },
          {
            icon: 'settings',
            title: 'route.administrative_tasks',
            links: [
            //Removed by the issue #76 as indicated by Meyyappan
            //{ url: '/settings/settings', name: 'route.settings' },
              { url: '/settings/units', name: 'route.units' },
              { url: '/settings/work_categories', name: 'route.work_categories' },
              { url: '/settings/translate', name: 'route.translate' },
            ]
          },
            /*
            //Removed by the issue #76 as indicated by Meyyappan
            {
            icon: 'event_note',
            title: 'route.inventory',
            links: [
              { url: '/settings/inventory_types', name: 'route.inventory_types' },
              { url: '/settings/inventory_events', name: 'route.inventory_events' }
            ]
          }
            */
        ]
      };
    },
    methods: {
      showScreen(url) {
        this.$router.push(url)
      },
    }
  }
</script>
<style>
.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.report-link {
  margin-bottom: 15px;
}

.report-link a {
  text-decoration: none; /* Убираем подчеркивание для ссылок, если нужно */
  color: #007bff; /* Цвет ссылки, можно изменить */
}

.report-link a:hover {
  text-decoration: underline; /* Подчеркивание при наведении */
}
</style>